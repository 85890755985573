import Vue from 'vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import 'font-awesome/css/font-awesome.min.css'
import globalComponents from "./assets/js/globalComponents.js"
import globalFilters from "./assets/js/globalFilters.js"
import { auth, getRouteAuth } from './assets/js/auth'
import * as Enum from './utils/enum'
Enum.register()


Vue.prototype.$getRouteAuth = (path = router.currentRoute.path) => new getRouteAuth(path)
Vue.prototype.$auth = auth

Vue.use(globalComponents)
Vue.use(globalFilters)
Vue.config.productionTip = false
import App from './App.vue'

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')